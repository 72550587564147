import './App.css';
import './assets/css/styles.css'
import PaymentFail from './components/PaymentFail';
import PaymentSuccess from './components/PaymentSuccess';
import Result from './components/Result';
import SubscriberCode from './components/SubscriberCode';
import PaymentProcess from './components/PaymentProcess';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

const EmptyPath=()=>{
  window.location = 'https://fpay.az/'
}

function App() {
  let navigate = useNavigate();
  
  return (
    <div className="App">
      
      <Routes >
        <Route exact path='/' element={<EmptyPath/>}></Route>
        <Route exact path="/:slug" element={<SubscriberCode />} />
        <Route exact path="/payment/:slug" element={<PaymentProcess />} />
        <Route exact path='/result/:slug' element={<Result />} />
        {/* <Route path="/success" element={<PaymentSuccess />} /> */}
        {/* <Route path='/error' element={<PaymentFail />} /> */}
      </Routes>
    </div>
  );
}

export default App;