import React from 'react';
import { Link } from 'react-router-dom';
import paymentFail from '../assets/imgs/paymentFail.png'

function PaymentFail() {
    return ( 
        <div className='page-cover'>
            <div className='payment-result payment-fail-result'>
                <div className='payment-fail'>
                    <img src={paymentFail} alt="payment-fail" className='payment-fail-img'/>
                    <h4 className='fail-tt'>Xəta baş verdi</h4>
                    <p className='failed-txt'>Ödənişiniz həyata keçirilə bilmədi</p>
                    <Link to="/" className='try-again-link'>Yenidən cəhd et</Link>
                </div>
            </div>
        </div>
     );
}

export default PaymentFail;