import React from 'react';
import paymentSuccess from '../assets/imgs/paymentSuccess.png';
import facebook from '../assets/imgs/Facebook.svg';
import linkedin from '../assets/imgs/LinkedIn.svg';
import instagram from '../assets/imgs/Instagram.svg';

function Footer() {
    return (
        <div className='subsc-footer'>
            <div className='subsc-footer-content'>
                <div className='subsc-footer-left'>
                    <p className='subsc-footer-p'>Sualınız var?  Bizimlə əlaqə saxlayın: </p>
                    <ul className='subsc-footer-info'>
                        <li><a href="tel:+994 12 310 17 18">+994 12 310 17 18</a></li>
                        <li><a href="mailto:info@fpay.az">info@fpay.az </a></li>
                    </ul>
                </div>
                <div className='subsc-footer-right'>
                    <ul className='subsc-sosials'>
                        <li>
                            <a href='https://www.facebook.com/fizzapay' target="_blank">
                                <img src={facebook} alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/fpay-online-payment-system/" target="_blank">
                                <img src={linkedin} alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/fpay.az" target="_blank">
                                <img src={instagram} alt="" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;