import React, { useState,useEffect, useRef ,useContext} from 'react';
import axios from 'axios';
import Footer from './Footer';
import { Link, useNavigate ,useParams} from 'react-router-dom';
import Select from 'react-select';
import sweetHouse from '../assets/imgs/sweetHouse.svg';
import payLogo from '../assets/imgs/payLogo.svg';
import azn from '../assets/imgs/azn.svg';
import chevrondown from '../assets/imgs/chevrondown.svg';
import visa from '../assets/imgs/visa.svg';
import mastercard from '../assets/imgs/mastercard.svg';
import gifLoader from '../assets/imgs/preloader.gif'

import BaseUrl from '../BaseUrl/BaseUrl';
import { MyContext } from '../context/PayContext';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function PaymentProcess() {
    const  params = useParams();
    // console.log(params)
    // let history = useHistory();

    const navigate = useNavigate();

    const [paymentAmountParams,setPaymentAmountParams] = useState({
        "maxAmount": 10000,
        "minAmount":0.01
    })
    const [userInformation,setUserInformation] =useState();
    const [paymentKey,setPaymentKey]=useState('')
    const [userTokenState,setUserTokenState] =useState(null); 
    const [billingKey,setBillingKey] =useState('')

    const [pageDetatilsState,setPagDetailsState] = useState(null);
    const [payBtnDisabled,setPayBtnDisabled] = useState(true)
    // Default expire date is not match with js new Date() time format
    const [modExpTime,setmodExpTime]=useState()
    
    const {
        pageIsLoading,
        setPageIsLoading,

        tokenGenerateFn,
        tokenTimeMod,
    } = useContext(MyContext);

    const[paymentProcessState,setPaymentProcessState]= useState(
        {
            "receiverCode":null,
            "amount":'',
            "cardType":""
        }
    );

    const options = [
        { value: 'v', label: <div className='payment-type-img'><span><img src={visa} /></span>Visa</div> },
        { value: 'm', label: <div className='payment-type-img'><span><img src={mastercard} /></span>Mastercard</div> },
    ]
    const customStyles = {
        option: (provided, state) => {
            return ({
                ...provided,
                color: state.isSelected ? '#2AB76E' : '#333',
                backgroundColor: state.isSelected ? 'transparent' : 'transparent',
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 5,
                paddingTop: 5,
            });
        },
    }

    const paymentSubmitHandler = (e) => {
        e.preventDefault()
        setPageIsLoading(true)
        // setPaymentProcessState(
        //     {
        //         "receiverCode":null,
        //         "amount":'',
        //         "cardType":""
        //     }
        // )
        // console.log(userTokenState)
        const tokenTimeModTemp = tokenTimeMod(userTokenState.exp_time); 
        if(tokenTimeModTemp > 0){

            axios.post(`${BaseUrl}/static/payment`,{
                    ...paymentProcessState,
                    "paymentKey":paymentKey,
                    "billingKey":billingKey,
            },{
                headers:{
                    'User-Api-Token': userTokenState.token, 
                    'Accept-Language': 'az-Latn-AZ', 
                    'Api-Request-Type': 'web-short', 
                    'Content-Type': 'application/json',
                    'User-Auth-Token': 'guest'
                }
            })
            .then(res=>{
                if (res.status ===200 ) {
                    localStorage.setItem('userPay',JSON.stringify({...JSON.parse(localStorage.getItem('userPay')),'paymentKey':paymentKey}))
                    window.location=res.data.datas.redirect;
                    setPageIsLoading(false)
                }
                else {
                    
                }
            })
            .catch(()=>{
                setPayBtnDisabled(true)
                        setPageIsLoading(false)
                        toast.error('Xəta baş verdi');
            })
        }else{
            (async()=>{
                await tokenGenerateFn()
                .then(res=>{
                    axios.post(`${BaseUrl}/static/payment`,{
                        ...paymentProcessState,
                        "paymentKey":paymentKey,
                        "billingKey":billingKey,
                    },{
                        headers:{
                            'User-Api-Token': res.token, 
                            'Accept-Language': 'az-Latn-AZ', 
                            'Api-Request-Type': 'web-short', 
                            'Content-Type': 'application/json',
                            'User-Auth-Token': 'guest'
                        }
                    })
                    .then(res=>{
                        if (res.status ===200 ) {
                            localStorage.setItem('userPay',JSON.stringify({...JSON.parse(localStorage.getItem('userPay')),'paymentKey':paymentKey,'userTokenState':res}))
                            window.location=res.data.datas.redirect;
                            setPageIsLoading(false)
                        }
                        else {
                            alert('Xəta baş verdi.') 
                            // navigate('/')
                        }
                    })
                    .catch(()=>{
                        setPayBtnDisabled(true)
                        setPageIsLoading(false)
                        toast.error('Xəta baş verdi');
                    })
                })
            })()
        }
       
    }

    const paymantChangeHandler = (paymentValue,paymentName) => {
        console.log()
        if(!isNaN(Number(paymentValue))){
            if((paymentAmountParams.maxAmount - Number(paymentValue)) > 0.1) {
                setPaymentProcessState({
                    ...paymentProcessState,
                    [paymentName]:paymentValue
                })
            }else{
                return false
            }
            
        }else{

            setPaymentProcessState({
                ...paymentProcessState,
                [paymentName]:paymentValue
            })
        }
        setPayBtnDisabled(false)
    }
    const inputChangeUpHandler=(e)=>{
        setPayBtnDisabled(false)
        // for(const key in inputOptionsState){
        //     if(inputOptionsState[key] !== ''){
        //         setDisableBtn(false)
        //     }else{
        //         setDisableBtn(true)
        //     }
        // }
    }
    useEffect(() => {

        const {billingKey,userTokenState} = JSON.parse(localStorage.getItem('userPay'));
        const {token,exp_time} =JSON.parse(localStorage.getItem('userPay')).userTokenState;

        const tokenTimeModTemp = tokenTimeMod(exp_time);
        // Checks if token expired
        if(tokenTimeModTemp > 0){

            if(JSON.parse(localStorage.getItem('userPay'))){
                setPagDetailsState(JSON.parse(localStorage.getItem('userPay')))

                new Promise((resolve)=>{
                    
                    setBillingKey(billingKey)
                    setUserTokenState(userTokenState)
                    
                    resolve({billKey:billingKey,tokenKey:token})
                })
                .then(res=>{

                    axios.post(`${BaseUrl}/static/billing`,{
                        "billingKey":res.billKey,
                    },{
                        headers:{
                            'User-Api-Token': res.tokenKey, 
                            'Accept-Language': 'az-Latn-AZ', 
                            'Api-Request-Type': 'web-short', 
                            'Content-Type': 'application/json', 
                            'User-Auth-Token': 'guest', 
                        }
                    }).then(res=>{
                       
                        setPaymentKey(res.data.datas.options[0].key)
                        setUserInformation(res.data.datas.userInformation)
                        // console.log(res.data.datas.userInformation)
                    })
                })
            }
        }else{

            (async()=>{
                await tokenGenerateFn()
                .then(res=>{
                    if(JSON.parse(localStorage.getItem('userPay'))){
                        setPagDetailsState(JSON.parse(localStorage.getItem('userPay')))

                        new Promise((resolve)=>{

                            setBillingKey(billingKey)
                            
                            //Update local storage with new token
                            localStorage.setItem('userPay',JSON.stringify({...JSON.parse(localStorage.getItem('userPay')),"userTokenState":res}))
                            // Update component's token state with new token 
                            setUserTokenState(res)
                            
                            //Passing new token and other datas need to billing 
                            // U can check with consol what are other datas 
                            resolve({billKey:billingKey,tokenKey:res.token})
                        })
                        .then(res=>{
                            axios.post(`${BaseUrl}/static/billing`,{
                                "billingKey":res.billKey,
                            },{
                                headers:{
                                    'User-Api-Token': res.tokenKey, 
                                    'Accept-Language': 'az-Latn-AZ', 
                                    'Api-Request-Type': 'web-short', 
                                    'Content-Type': 'application/json', 
                                    'User-Auth-Token': 'guest', 
                                }
                            }).then(res=>{
                                //Setting these for use inside of component
                                setPaymentKey(res.data.datas.options[0].key)
                                setUserInformation(res.data.datas.userInformation)
                            })
                        })
                    }
                })
            })()
            
        }
        
    }, []);


    return (
        <div className="page-cover">
            <ToastContainer />
            <div className="subscriber-cover">
                <div className="subsc-section">
                    <form action="" onSubmit={paymentSubmitHandler}>
                        <div className='subsc-section-content'>
                            <div className='subsc-left'>
                                <div className='subsc-top-imgs'>
                                    <img src={pageDetatilsState !== null ? pageDetatilsState.image:''} alt="" className='subsc-left-img' />
                                    <img src={payLogo} alt="" className='subsc-right-img' />
                                </div>
                                <div className="subsc-form">
                                    <div className="form-element">
                                        {/* onChange={(e) => buttonDisabled(e)} */}
                                        <input 
                                            type="number" 
                                            placeholder='Ödəniləcək məbləğ' 
                                            name="amount"
                                            value={paymentProcessState.amount} 
                                            className='form-input' 
                                            onKeyUp={inputChangeUpHandler}
                                            onChange={(e)=>paymantChangeHandler(e.target.value,e.target.name)}
                                        />
                                        <img src={azn} alt="" className='azn-icon' />
                                    </div>
                                    <div className="form-element payment-type">
                                        <Select 
                                            options={options}  
                                            placeholder='Kartın tipi'
                                            name="cardType"
                                            isSearchable={false} 
                                            styles={customStyles} 
                                            onChange={(e,elem)=>paymantChangeHandler(e.value,elem.name)}
                                        />
                                        <img src={chevrondown} alt="" className='select-icon' />
                                    </div>
                                </div>
                            </div>
                            <div className='subsc-right'>
                                <div className='subsc-right-top'>
                                    <img src={payLogo} alt="" className='subsc-right-img' />
                                </div>
                                <ul className="subsc-data">
                                   

                                    {
                                        userInformation ? Object.keys(userInformation).map(item=>(

                                            userInformation[item] ? 
                                               
                                                <li key={item}>
                                                    <span>
                                                       {
                                                            item === 'name' ? 'Abonent' : (
                                                            item==='addr'?'Adres':(
                                                            item==='number'?'Abonent Kodu':(
                                                            item==='infos'?'Məlumat':'')))
                                                       }
                                                    </span>
                                                    <p>
                                                        {
                                                            userInformation ? userInformation[item] :'Məlumat yüklənir'
                                                        }
                                                    </p>
                                                </li>
                                            :''
                                        )):''
                                    }

                                    
                                 
                                </ul>
                                <div className="payment-btns">
                                    <Link to={`/${params.slug}`} className='back-btn'>Geri qayıt</Link>
                                    <button 
                                        type='submit' 
                                        className='pay-btn' 
                                        disabled={payBtnDisabled}
                                    >
                                        {
                                            pageIsLoading ? 
                                                <span>
                                                    <img src={gifLoader} style={{width:'25px',height:'25px'}} alt="" />
                                                </span> 
                                            : 'Ödəniş et'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default PaymentProcess;

