import React,{useState,useEffect,useContext} from 'react';
import PaymentSuccess from './PaymentSuccess';
import PaymentFail from './PaymentFail';
import axios from 'axios';
import { MyContext } from '../context/PayContext';
import BaseUrl from '../BaseUrl/BaseUrl';
const Result = () => {
    const [success,setSuccess] = useState(true);

    const {tokenGenerateFn,tokenTimeMod}= useContext(MyContext);
    // console.log()
    const {billingKey,userTokenState,paymentKey} = JSON.parse(localStorage.getItem('userPay'));
    // console.log()
    useEffect(()=>{
            if(tokenTimeMod>0){
                axios.post(`${BaseUrl}/static/result`,{
                    "paymentKey":paymentKey
                },{
                    headers:{
                        'User-Api-Token': userTokenState, 
                        'Accept-Language': 'az-Latn-AZ', 
                        'Api-Request-Type': 'web-short', 
                        'Content-Type': 'application/json', 
                        'User-Auth-Token': 'guest'
                    }
                }).then(res=>{
                    // if(res.status===200){
                    //     setSuccess(true)
                    // }else{
                    //     // setSuccess(false)
                    // }
                    console.log(res)
                }).catch(err=>{
                    // setSuccess(false)
                    console.log(err )
    
                })
            }else{
                (async()=>{
                    await tokenGenerateFn()
                    .then(res=>{
                        console.log(res.token , paymentKey)
                        axios.post(`${BaseUrl}/static/result`,{
                            "paymentKey":paymentKey
                        },{
                            headers:{
                                'User-Api-Token': res.token, 
                                'Accept-Language': 'az-Latn-AZ', 
                                'Api-Request-Type': 'web-short', 
                                'Content-Type': 'application/json', 
                                'User-Auth-Token': 'guest'
                            }
                        }).then(res=>{
                            // if(res.status===200){
                            //     setSuccess(true)
                            // }else{
                            //     // setSuccess(false)
                            // }
                            // console.log(res)
                        }).catch(err=>{
                            // setSuccess(false)
            
                        })
                    })
                })()
            }
    },[])
    return (
        <div>
            {
                success ? <PaymentSuccess/> : <PaymentFail/>
            }
        </div>
    );
}

export default Result;
