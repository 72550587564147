import React from 'react';
import paymentSuccess from '../assets/imgs/paymentSuccess.png'

function PaymentSuccess() {
    return ( 
        <div className='page-cover'>
            <div className='payment-result payment-success-result'>
                <div className='payment-success'>
                    <img src={paymentSuccess} alt="payment-fail" className='payment-success-img'/>
                    <h4 className='success-tt'>Təşəkkür edirik</h4>
                    <p className='success-txt'>Ödənişiniz uğurla həyata keçirildi</p>
                </div>
                <div className='payment-info'>
                    <div className='payment-info-head'>
                        <p>03.03.2022 - 15:20</p>
                        <span>150₼</span>
                    </div>
                    <ul className='payment-info-details'>
                        <li>
                            <p>Qəbz nömrəsi</p>
                            <span>4418-af44-cc1ef625776a</span>
                        </li>
                        <li>
                            <p>Kateqoriya</p>
                            <span>Kommunal</span>
                        </li>
                        <li>
                            <p>Provayder</p>
                            <span>Sweethouse</span>
                        </li>
                        <li>
                            <p>Vəsait silinəcək kart</p>
                            <span>4*** **** **** 9851</span>
                        </li>
                        <li>
                            <p>Abonent kodu</p>
                            <span>0835</span>
                        </li>
                        <li>
                            <p>Abonent</p>
                            <span>Rəcəbli Fikrət Xəyal oğlu</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
     );
}

export default PaymentSuccess;