import React,{createContext,useState,useEffect} from 'react';
import axios from 'axios';
import BaseUrl from '../BaseUrl/BaseUrl';
export const MyContext = createContext();


export default function PayContext(props) {

    
    const [pageIsLoading,setPageIsLoading] = useState(false)

    const [userTokenState,setUserTokenState] = useState({})
    const [merchantIdState,setMerchantIdState] = useState('')
    const [billingKey,setBillingKey] = useState('')


    const [paymentKey,setPaymentKey] = useState('')
    const [userInformation,setUserInformation] = useState(null)
    
    const tokenGenerateFn= async()=>{
        const {data} =  await axios.post(`${BaseUrl}/static/token`,
                {
                    username: 'fpay',
                    password: '!oKjsI@Ed]'
                },{
                    headers:{
                        'Api-Request-Type': 'web-short', 
                        'Content-Type': 'application/json', 
                    }
                }
        )
        return data.datas       
    }

    const tokenTimeMod=(exp_time)=>{
        let utcSeconds = exp_time;
        let tokenExpTime = new Date(0); 
        tokenExpTime.setUTCSeconds(utcSeconds);


        return tokenExpTime - new Date()

    }

    useEffect(()=>{
        // console.log(userTokenState)
        
    },[])
    return (
		<MyContext.Provider  
            value={{
                tokenGenerateFn,
                tokenTimeMod,


                pageIsLoading,
                setPageIsLoading,

                userTokenState,
                setUserTokenState,

                merchantIdState,
                setMerchantIdState,
                
                billingKey,
                setBillingKey,

                paymentKey,
                setPaymentKey,
                userInformation,
                setUserInformation,

            }}
        >
            {props.children}
        
        </MyContext.Provider>
	)
}