import React, { useState,useContext,useEffect } from 'react';
import axios from 'axios'
import Select from 'react-select';
import sweetHouse from '../assets/imgs/sweetHouse.svg';
import payLogo from '../assets/imgs/payLogo.svg';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import chevrondown from '../assets/imgs/chevrondown.svg';
import azn from '../assets/imgs/azn.svg';
import BaseUrl from '../BaseUrl/BaseUrl';
import {MyContext} from '../context/PayContext';
import gifLoader from '../assets/imgs/preloader.gif'

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function SubscriberCode() {
    localStorage.clear()
    /* User enterder code to input */
    const [inputState,setInputState] = useState([]);
    const [inputOptionsState,setInputOptionsState] = useState({});


    const [pageDetatilsState,setPagDetailsState] = useState(null);
    const [merchantId,setMerchantId]= useState('');
    const [userTokenState,setUserTokenState] = useState(null);
    const [pageHrefCode,setpageHrefCode] = useState('');
    
    /* #dataList used for objects inside of array which is need to fields's field's keys . */
    const [datasList,setDatasList] = useState([]);
    const [disableBtn,setDisableBtn] = useState(true)

    const {
        tokenGenerateFn,
        tokenTimeMod,

        pageIsLoading,
        setPageIsLoading,
    } = useContext(MyContext);
    

    

    //******************************* */
    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()
        setPageIsLoading(true)
        const fieldsTempObj={};
        setDisableBtn(true)
       
        const tokenTimeModTemp = tokenTimeMod(userTokenState.exp_time);
        // Checks if token expired
        if(tokenTimeModTemp > 0){
            new Promise((resolve,reject)=>{
                let billingFields = {};
                inputState.forEach(input=>{
                    if(inputOptionsState[input.name]){
                        billingFields[input.id]=inputOptionsState[input.name]
                    }
                })
                resolve(billingFields);
                
            }).then(res=>{
                axios.post(`${BaseUrl}/static/billing/${merchantId}`,
                    {
                        "fields": res
                    },
                    {
                        headers: { 
                            'User-Api-Token':  userTokenState.token, 
                            'Accept-Language': 'az-Latn-AZ', 
                            'Api-Request-Type': 'web-short', 
                            'Content-Type': 'application/json', 
                            'User-Auth-Token': 'guest'
                        }
                    }
                )
                .then(res=>{
                    if(res.data.code===200){
                        setPageIsLoading(false)
                        // setBillingKey(res.data.datas.billingKey)
                    
                        localStorage.setItem('userPay',JSON.stringify({'billingKey':res.data.datas.billingKey,'userTokenState':userTokenState,'image':pageDetatilsState.datas.image}))
                    }
                })
                .then(res=>{
                    navigate(`/payment/${pageHrefCode}`)
                    
                }).catch(err=>{
                    // alert('Xəta baş verdi.')
                    // window.location.reload()
                    setDisableBtn(true)
                    setPageIsLoading(false)
                    toast.error('Xəta baş verdi');
                })
            })
           
        }else{
            (async()=>{
                await tokenGenerateFn()
                .then(res=>{
                    let billingFields = {};
                    inputState.forEach(input=>{
                        if(inputOptionsState[input.name]){
                            billingFields[input.id]=inputOptionsState[input.name]
                        }
                    })
                    return {
                        ...res,
                        billingFields
                    }
                })
                .then(({token , exp_time, billingFields})=>{
                    axios.post(`${BaseUrl}/static/billing/${merchantId}`,
                        {
                            "fields": billingFields
                        },
                        {
                            headers: { 
                                'User-Api-Token':  token, 
                                'Accept-Language': 'az-Latn-AZ', 
                                'Api-Request-Type': 'web-short', 
                                'Content-Type': 'application/json', 
                                'User-Auth-Token': 'guest'
                            }
                        }
                    )
                    .then(res=>{
                        console.log()
                        if(res.data.code===200){
                            setPageIsLoading(false)
                            localStorage.setItem('userPay',JSON.stringify({'billingKey':res.data.datas.billingKey,'userTokenState':{token,exp_time},'image':pageDetatilsState.datas.image}))
                        }
                    })
                    .then(res=>{
                        navigate(`/payment/${pageHrefCode}`)
                        
                    }).catch(err=>{
                        setDisableBtn(true)
                        setPageIsLoading(false)
                        toast.error('Xəta baş verdi');
                    })
                })
            })()
            
          
        }
       
    }

    const inputChangeHandler=(e)=>{
        // console.log(inputOptionsState)
        for(const key in inputOptionsState){
            if(inputOptionsState[key] !== ''){
                setDisableBtn(false)
            }else{
                setDisableBtn(true)
            }
        }
        
        if(e.value){
            setInputOptionsState({...inputOptionsState,[e.key]:e.value});
        }else{
            setInputOptionsState({...inputOptionsState,[e.target.name]:e.target.value})
        }

        // setInputOptionsState({...inputOptionsState,[e.key]:e.value});
            
    }
    const inputChangeUpHandler=(e)=>{
        for(const key in inputOptionsState){
            if(inputOptionsState[key] !== ''){
                setDisableBtn(false)
            }else{
                setDisableBtn(true)
            }
        }
    }
    // const inputChangeUpHandler=()=>{
    //     setControlDown(false)
            
    // }
    // master card  
    //  arrow input 
    // redirect fpay.az without /companycomany
    // back btn from payment 


    
    

    

    // inputs values display to right col



    // 




    useEffect(()=>{
        (async()=>{
            setpageHrefCode(window.location.href.split('/')[window.location.href.split('/').length-1])
            /* Calling tokenGenerateFn() from payContext to get token */
            const {token,exp_time} = await tokenGenerateFn();
            console.log(window.location.href.split('/')[window.location.href.split('/').length-1])
            // Keeps token infos in state to set to localStorage and -- 
            // --> use in at next component till expire  
            setUserTokenState(await tokenGenerateFn())
           
            // Service getting info about company and some inso about to UI
            await axios.post(`${BaseUrl}/static/short`,JSON.stringify({
                            "code":window.location.href.split('/')[window.location.href.split('/').length-1]
            }),{
                            headers:{
                                'User-Api-Token': token, 
                                'Accept-Language': 'az-Latn-AZ', 
                                'Api-Request-Type': 'web-short', 
                                'Content-Type': 'application/json', 
                                'User-Auth-Token': 'guest', 
                            }
             })
            .then(res=>{
                
                setInputState(res.data.datas.list)
                let tempNames = {}
                res.data.datas.list.forEach(item => {
                    tempNames[item.name]=''
                });
                setInputOptionsState(tempNames)
                /* Using for general UI details , forexample storing img url */
                setPagDetailsState(res.data)
                // These infos using while submit form
                // check them up above inside of handleSubmit
          
                setMerchantId(res.data.datas.merchantID)
        
            }).catch(err=>{
                // window.location='https://fpay.az/'
            })
        })()
    },[])
                    // "code":"UsLWTjXQ"
                    // "code":"EOMyXQvj"
                    // "code":"TwhtsItc"
    const customStyles = {
        option: (provided, state) => {
            return ({
                ...provided,
                color: state.isSelected ? '#2AB76E' : '#333',
                backgroundColor: state.isSelected ? 'transparent' : 'transparent',
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 5,
                paddingTop: 5,
            });
        },
    }


    return (
        <div className='page-cover'>
           <ToastContainer />
            <div className='subscriber-cover'>
                <div className='subsc-section'>
                    <div className='subsc-section-content'>
                        <div className='subsc-left'>
                            <div className='subsc-top-imgs'>
                                <img src={pageDetatilsState !==null ?pageDetatilsState.datas.image:''} alt="" className='subsc-left-img' /> 
                                <img src={payLogo} alt="" className='subsc-right-img' />
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="subsc-form">
                                    <div className="form-element">
                                        {
                                            inputState.map(input => input.type === 'select' ?
                                                    (
                                                        !input.condition ? (
                                                            <div className="form-element payment-type" key={input.id}>
                                                                <Select 
                                                                    options={
                                                                        Object.keys(input.options).map((key,index)=>(
                                                                            {   
                                                                                key:input.name,
                                                                                value: key,
                                                                                // label:{key:input.name},
                                                                                label: <div className=''><span> {input.options[key]} </span></div> 
                                                                            }
                                                                        )) 
                                                                    }  
                                                                    placeholder={input.title}
                                                                    name={input.name}
                                                                    styles={customStyles} 
                                                                    onChange={inputChangeHandler}
                                                                />
                                                                <img src={chevrondown} alt="" className='select-icon' />
                                                        </div>
                                                        ): (inputOptionsState[input.condition.paramName] === input.condition.paramValue? (
                                                            <div className="form-element payment-type" key={input.id}>
                                                                <Select 
                                                                    options={
                                                                        Object.keys(input.options).map((key,index)=>(
                                                                            {   
                                                                                key:input.name,
                                                                                value: key,
                                                                                // label:{key:input.name},
                                                                                label: <div className=''><span> {input.options[key]} </span></div> 
                                                                            }
                                                                        )) 
                                                                    }  
                                                                    placeholder={input.title}
                                                                    name={input.name}
                                                                    styles={customStyles} 
                                                                    onChange={inputChangeHandler}
                                                                />
                                                                <img src={chevrondown} alt="" className='select-icon' />
                                                        </div>
                                                        ) :'') 
                                                    ):(!input.condition ? (
                                                        <div className="form-element payment-type" key={input.id}>
                                                            <input 
                                                                type="text" 
                                                                placeholder={input.title} 
                                                                name={input.name}
                                                                value={inputOptionsState[input.name]}
                                                                onChange={inputChangeHandler}
                                                                onKeyUp={inputChangeUpHandler}
                                                                className='form-input' min="1"
                                                                autoComplete="off"
                                                            />
                                                        </div>    
                                                    )
                                                    :(inputOptionsState[input.condition.paramName] === input.condition.paramValue ? 
                                                        <div className="form-element payment-type" key={input.id}>
                                                            <input 
                                                                type="text" 
                                                                placeholder={input.title} 
                                                                name={input.name}
                                                                value={inputOptionsState[input.name]}
                                                                onChange={inputChangeHandler}
                                                                className='form-input' min="1" 
                                                            />
                                                        </div> 
                                                    :''))
                                                    
                                            )

                                        }
                                    </div>
                                    {/* disabled={!subcode} */}
                                    <button type='submit' className='subsc-continue' disabled={disableBtn}  >
                                        {
                                            pageIsLoading ? 
                                                <span>
                                                    <img src={gifLoader} style={{width:'25px',height:'25px'}} alt="" />
                                                </span> 
                                            : 'Davam et'
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='subsc-right'>
                            <div className='subsc-right-top'>
                                <img src={payLogo} alt="" className='subsc-right-img' />
                            </div>
                            <ul className="subsc-data">
                                <li>
                                    <span>Ödəniş parametrləri</span>
                                    <p>-</p>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default SubscriberCode;